<template>
  <div>
    <el-dialog :title="text+'分组'"
               :visible.sync="dialogVisible"
               v-dialogDrag
               :close-on-click-modal="false"
               :append-to-body="true"
               width="400px"
               :before-close="handleClose">

      <el-row :gutter="10">
        <el-form :model="form"
                 :rules="rules"
                 label-width="100px">
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="分组名称"
                            prop="name">
                <el-input placeholder="请输入"
                          v-model="form.name"
                          style="width:100%" />
              </el-form-item>
            </el-col>

            <!-- <el-col :span="24">
              <el-form-item label="所属学校"
                            prop="school_id">
                <el-select v-model="form.school_id"
                           filterable
                           clearable
                           style="width:100%"
                           placeholder="选择学校">
                  <el-option v-for="item in schoolList"
                             :key="item.school_id"
                             :label="item.school_name"
                             :value="item.school_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> -->

            <el-col :span="24">
              <el-form-item label="是否启用">
                <el-radio-group v-model="form.status">
                  <el-radio :label="1">启用</el-radio>
                  <el-radio :label="0">禁用</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>

          </el-row>
        </el-form>
      </el-row>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary"
                   @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      dialogVisible: false,
      form: {},
      text: '新增',
      rules: {
        name: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        school_id: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      },
      schoolList: []
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
    },
    save () {
      this.$http({
        url: '/api/v1/organize/create',
        method: 'post',
        data: this.form
      }).then(res => {
        this.$notify({
          title: '提示',
          message: '操作成功!',
          type: 'success'
        });
        this.$parent.search()
        this.handleClose()
      })
    },
    // getSchoolList () {
    //   this.$http({
    //     url: '/api/v1/main/get_user_school',
    //     method: 'get'
    //   }).then(res => {
    //     this.schoolList = res.data.list
    //   })
    // },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>